img {max-width: 100% ;} 

/* disable css animations */
.touch .animated {
	-webkit-animation-duration: 0;
	animation-duration: 0;
	-webkit-animation-delay: 0;
	animation-delay: 0;
} 
/* disable background-size:cover */
.touch #container_header, .touch #header_bg, .touch #container_top2_modules, .touch #container_bottom1_modules, .touch #container_bottom2_modules {
	-webkit-background-size: 	auto !important;
	-moz-background-size: 		auto !important;
	-o-background-size: 		auto !important;
	background-size: 			auto !important;
	background-repeat: repeat !important;
	background-attachment: scroll !important;
}


/* All touch devices */
.touch .wrapper960 {
	width:100% !important;
}
.touch body {padding: 0;}
.touch body:before, .touch body:after {display: none;}
.touch #container_spacer1, .touch .hornav, .touch #socialmedia, .touch .copyright {display: none;}
.touch .slicknav_menu {display:block;}
.touch .#logo {padding-top: 20px; padding-bottom: 20px;}
.touch .sidebar_wrapper {
	width:100%;
	position: relative;
	height: auto;
	margin: 0;
	overflow: visible;
	background-image: none !important;
}
.touch .content_wrapper {margin-left: 0px;}

/* #Tablet (Landscape)
================================================== */

@media only screen and (max-width: 1024px) {

}

/* #Tablet (Portrait)
================================================== */
    
@media only screen and (max-width: 959px) {

}

/* #Mobile (Landscape)
================================================== */

@media only screen and (max-width: 767px) {

.sidecol_a, .sidecol_b, .items-row .item {
	width:100% !important;
}
.backgrounds .sidecol_a, .backgrounds .sidecol_b {display:none;}
#content_remainder {
	width: 100% !important;
}
.top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2 {
	width:100% !important;
}
.row-fluid [class*="span"] {margin-left:0;}

blockquote {
  background: none;
  padding: 0 0 5px 0px;
}
blockquote p {
  background: none;
  padding: 0 0px 10px 0px;
}

#container_slideshow, #container_top1_modules, #container_top2_modules, #container_top3_modules, #container_main, #container_bottom1_modules, 
#container_bottom2_modules, #container_base, #container_copyright, #container_footermenu {
	border-right-width: 0;
	border-left-width: 0;
}
html {overflow-x: hidden;}

/*.slick-slider, .slick-slider img, .layerslideshow .info {
    max-height: 280px;
}
.layerslideshow .info {
    line-height: 280px;
}*/

}

/*  #Mobile (Portrait)
================================================== */

    /* Note: Design for a width of 320px */

@media only screen and (max-width: 440px) {

#content_remainder, .items-row .item {
    width: 100% !important;
}
.row-fluid [class*="span"] {margin-left:0;}
.top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2, .sidecol_a, .sidecol_b {
	width:100% !important;
}
#container_main .wrapper960 {
    margin-top: 0px;
}
#footermenu {
    float: left;
}

/*.slick-slider, .slick-slider img, .layerslideshow .info {
    max-height: 460px;
}

.layerslideshow .info {
    line-height: 460px;
}*/

}


